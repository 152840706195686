/**
 * Header component that queries for data
 * with Gatsby's StaticQuery component.
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */
import { Link, StaticQuery, graphql } from 'gatsby'
import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
// import NavDropdown from 'react-bootstrap/NavDropdown'
import Container from 'react-bootstrap/Container'
import './header.css'

const Header = () => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Navbar collapseOnSelect expand="lg" variant="dark">
          <Container id="container-header">
            <Link id="logo-header" to="/">
              {data.site.siteMetadata.title}
            </Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="nav-link-header ml-auto"></Nav>
              {/* <NavDropdown
                title="Uued mesilad"
                id="nav-dropdown"
                className="ml-auto"
              >
                <Link className="dropdown-item" to="/latest-table">
                  nimekiri
                </Link>
                <Link className="dropdown-item" to="/latest-map">
                  kaart
                </Link>
              </NavDropdown> */}
              <Nav>
                <Link className="nav-link-header" to="/latest">
                  Uued mesilad
                </Link>
              </Nav>
              <Nav>
                <Link className="nav-link-header" to="/contact">
                  Kontakt
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    )}
  />
)

export default Header
